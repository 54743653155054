import { atom } from "recoil";

const resetStateAtom = atom({
  key: "resetStateAtom",
  default: {
    mobile: "",
    email: "",
    emailOtp: "",
    mobileOtp: "",
    radioValue: "",
  },
});
export { resetStateAtom };
