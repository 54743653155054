import { selector } from "recoil";
import { resetStateAtom } from "../atom";

const resetStateSelector = selector({
  key: "resetStateSelector",
  get: async ({ get }) => {
    try {
      const data = get(resetStateAtom);
      return data;
    } catch (e) {
      console.log(e.message);
    }
  },
  set: ({ set }, newValue) => {
    set(resetStateAtom, newValue);
  },
});
export { resetStateSelector };
