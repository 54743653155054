import { selector } from "recoil";
import { auth2faStateAtom } from "../atom";

const auth2faSelector = selector({
  key: "auth2faSelector",
  get: async ({ get }) => {
    try {
      const data = get(auth2faStateAtom);
      return data;
    } catch (e) {
      console.log(e.message);
    }
  },
  set: ({ set }, newValue) => {
    set(auth2faStateAtom, newValue);
  },
});
export { auth2faSelector };
