import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  notification,
  Button,
  Modal,
  Radio,
  Divider,
} from "antd";
import { useRecoilState } from "recoil";
import Logo from "../../assets/img/chalomart_logo.svg";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../../route";
import OtpInput from "react-otp-input";
import { registerformState } from "../../recoil";
import { SERVER_URI } from "../../utils/server.constant";
const { Search } = Input;

const SignUp = () => {
  const history = useHistory();
  const [regData, setRegData] = useRecoilState(registerformState);
  const [isVerify, setIsVerify] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [isSuccessNo, setIsSuccessNo] = useState(false);
  const [buyerFilter, setBuyerFilter] = useState([]);
  const [sellerFilter, setSellerFilter] = useState([]);
  const [catFilter, setCatFilter] = useState([]);
  const [industryFilter, setIndustryFilter] = useState([]);

  const handleOnChange = (e) => {
    setRegData({
      ...regData,
      [e.target.name]: e.target.value,
    });
  };
  const handleGstOnChange = (e) => {
    if (!isVerify && isSuccessNo) {
      // setIsVerify(true);
      setIsSuccessNo(false);
    } else {
      setRegData({
        ...regData,
        [e.target.name]: e.target.value,
      });
    }
    setRegData({
      ...regData,
      [e.target.name]: e.target.value,
    });
  };
  const handleIndustryChange = async (e) => {
    let Categories = [];
    let supplyChain = [];
    regData?.industryArr &&
      (await regData?.industryArr?.length) > 0 &&
      regData.industryArr.map((item, i) => {
        i + 1 === Number(e) &&
          Categories.push(item.categories) &&
          supplyChain.push(item.supplyChains);
      });
    await setRegData({
      ...regData,
      selectedIndustry: e,
      categories: Categories[0],
      supply_chain: supplyChain[0],
      selectedCategories: [],
      selectedSuppyChain: [],
    });
    setIndustryFilter([]);
  };

  const _setIndustry = async (result) => {
    let IndustryArr = [];
    result?.industries?.length > 0 &&
      result.industries.map((item) => {
        IndustryArr.push({
          id: item.id,
          name: item.name,
        });
      });
    await setRegData({
      ...regData,
      showModal: false,
      optVerified: true,
      industryArr: result?.industries,
      industry: IndustryArr,
    });
  };
  const _fetchDetails = async () => {
    await fetch(`${SERVER_URI}passport/details`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(async (result) => {
        _setIndustry(result.data);
      });
  };
  // useEffect(()=>{
  //   _fetchDetails()
  // },[])
  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  // if (regData.turnOver === "1") {
  //   notification.error({
  //     message: "PAN Number",
  //     description: "Pan number is not verified",
  //     placement: "topRight",
  //   });
  // } else {
  //   notification.error({
  //     message: "GST Number",
  //     description: "GST number is not verified",
  //     placement: "topRight",
  //   });
  // }

  const onFinish = async (values) => {
    const Supplychains = JSON.stringify({
      buyerChains: regData?.buyer_supply_chain,
      sellerChains: regData?.seller_supply_chain,
    });
    await setRegData({
      ...regData,
      email: values.email,
      name: values.name,
      phone: values.phone,
      prefix: values.prefix,
      turnOver: values.turnOver,
      userType: values.userType,
    });
    if (regData?.optVerified) {
      if (isSuccessNo) {
        const bodyValues = JSON.stringify({
          companyType: values.userType,
          turnover: values.turnOver,
          name: values.name,
          email: values.email,
          mobile: values.prefix + values.phone,
          password: values.password,
          pan: values.turnOver == 1 ? values.pan : null,
          gst: values.turnOver == 2 ? values.gst : null,
          companyName: companyName,
          industryID: regData?.selectedIndustry,
          categories: JSON.stringify(values.category),
          tradeName: regData?.tradeName,
          premisePlot: regData?.premisePlot,
          premiseBuilding: regData?.premiseBuilding,
          premiseStreet: regData?.premiseStreet,
          premisePin: regData?.premisePin,
          supplyChains: Supplychains,
        });
        try {
          await fetch(`${SERVER_URI}passport/signup`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: bodyValues,
          })
            .then((response) => response.json())
            .then((result) => {
              console.log(result);
              if (result.flag) {
                history.push(ROUTES.LOGIN);
                setRegData({
                  ...regData,
                  optVerified: false,
                });
                openNotificationWithIcon("success", "Success", result.info);
              } else {
                console.log(result.info);
                openNotificationWithIcon("error", "Error", result.info);
              }
            });
        } catch (err) {
          console.log("err", err);
          openNotificationWithIcon("error", "Error", "Sign up failed");
        }
      } else {
        if (regData.turnOver === "1") {
          notification.error({
            message: "PAN Number",
            description: "Pan number is not verified",
            placement: "topRight",
          });
        } else {
          notification.error({
            message: "GST Number",
            description: "GST number is not verified",
            placement: "topRight",
          });
        }
      }
    } else {
      try {
        await fetch(`${SERVER_URI}passport/inquiry`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: values.userType,
            turnover: values.turnOver,
            name: values.name,
            email: values.email,
            mobile: values.prefix + values.phone,
          }),
        })
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            if (result.flag) {
              setRegData({
                ...regData,
                showModal: true,
              });
              openNotificationWithIcon("success", "Success", result.info);
            } else {
              console.log(result.info);
              openNotificationWithIcon("error", "Error", result.info);
            }
          });
      } catch (err) {
        console.log("err", err);
        openNotificationWithIcon("error", "Error", "Otp failled");
      }
    }
  };
  const _verifymobemail = async () => {
    if (
      regData.mobOtp === "" ||
      regData.mobOtp === null ||
      regData.mobOtp === undefined
    ) {
      openNotificationWithIcon("error", "Error", "Enter Valid Mobile OTP");
    } else if (
      regData.emailOtp === "" ||
      regData.emailOtp === null ||
      regData.emailOtp === undefined
    ) {
      openNotificationWithIcon("error", "Error", "Enter Valid E-mail OTP");
    } else {
      try {
        await fetch(`${SERVER_URI}passport/verify`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            init: "inquiry",
            email: regData?.email,
            mobile: regData?.prefix + regData?.phone,
            emailOTP: regData?.emailOtp,
            mobileOTP: regData?.mobOtp,
          }),
        })
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            if (result.flag) {
              _fetchDetails();
              setRegData({
                ...regData,
                showModal: false,
                optVerified: true,
              });

              openNotificationWithIcon("success", "Success", result.info);
            } else {
              console.log(result.info);
              openNotificationWithIcon("error", "Error", result.info);
            }
          });
      } catch (err) {
        openNotificationWithIcon("error", "Error", "Invalid OTP");
      }
    }
  };
  const _resendOtp = async () => {
    try {
      await fetch(`${SERVER_URI}passport/resend`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          init: "inquiry",
          email: regData.email,
          mobile: regData.prefix + regData.phone,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.flag) {
            openNotificationWithIcon("success", "Success", result.info);
          } else {
            console.log(result.info);
            openNotificationWithIcon("error", "Error", result.info);
          }
        });
    } catch (err) {
      openNotificationWithIcon("error", "Error", "Resend OTP");
    }
  };
  const handleVerify = async () => {
    setIsVerify(true);
    if (regData?.pan !== "") {
      try {
        await fetch(`${SERVER_URI}passport/identity`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pan: regData?.pan,
          }),
        })
          .then((response) => response.json())
          .then((result) => {
            if (result?.data?.response_code === "101") {
              setIsVerify(false);
              setCompanyName(result?.data?.data?.name);
              setIsSuccessNo(true);
              setRegData({
                ...regData,
                companyName: result?.data?.data?.name,
              });
              setCompanyName(result?.data?.data?.name);
              openNotificationWithIcon("success", "Success", result.info);
            } else {
              setIsVerify(false);
              console.log(result.info);
              openNotificationWithIcon("error", "Error", result.info);
            }
          });
      } catch (err) {
        openNotificationWithIcon("error", "Error", "Enter valid pan number");
      }
    } else {
      openNotificationWithIcon("error", "Error", "Enter valid pan number");
    }
  };
  const handleVerifyGst = async () => {
    setIsVerify(true);
    if (regData?.gst !== "") {
      console.log("regData?.gstregData?.gst", regData?.gst);
      try {
        await fetch(`${SERVER_URI}passport/identity`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            gst: regData?.gst,
          }),
        })
          .then((response) => response.json())
          .then((result) => {
            console.log("result", result?.data?.data);
            if (result?.data?.response_code === "101") {
              setRegData({
                ...regData,
                tradeName: result?.data?.result?.tradeNam,
                premisePlot: result?.data?.result?.pradr?.addr?.bno,
                premiseBuilding: result?.data?.result?.pradr?.addr?.bnm,
                premiseStreet: result?.data?.result?.pradr?.addr?.dst,
                premisePin: result?.data?.result?.pradr?.addr?.pncd,
              });
              setCompanyName(result?.data?.result?.lgnm);
              setIsVerify(false);
              setIsSuccessNo(true);
              openNotificationWithIcon("success", "Success", result.info);
            } else {
              setIsVerify(false);
              console.log(result.info);
              openNotificationWithIcon("error", "Error", result.info);
            }
          });
      } catch (err) {
        throw new Error(err);
      }
    } else {
      openNotificationWithIcon("error", "Error", "Enter valid Gst number");
    }
  };
  const { Option } = Select;
  const [form] = Form.useForm();
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        getPopupContainer={(trigger) => trigger.parentNode}
        style={{ width: 90 }}
        name="prefix"
        disabled={regData?.optVerified && true}
        onChange={(e) =>
          setRegData({
            ...regData,
            prefix: e,
          })
        }
      >
        <Option value="+91">+91</Option>
      </Select>
    </Form.Item>
  );
  const VerifyNumber = (
    <Form.Item name="verifyNum" noStyle>
      <Button
        style={{ width: "100%", height: "100%" }}
        size="small"
        type="primary"
        onClick={() =>
          regData.turnOver === "1" ? handleVerify() : handleVerifyGst()
        }
      >
        Verify {regData.turnOver === "1" ? "PAN" : "GST"}
      </Button>
    </Form.Item>
  );

  return (
    <Row
      className="login-flow-bg"
      style={{ width: "100%", height: "100%" }}
      justify="center"
    >
      <Row justify="center" align="middle" style={{ zIndex: 1 }}>
        <Form
          className="login-box"
          size="large"
          style={{
            backgroundColor: "white",
            height: regData?.optVerified ? "90vh" : "auto",
            overflow: "scroll",
            width: 480,
            padding: 20,
          }}
          form={form}
          layout="vertical"
          name="register"
          onFinish={onFinish}
          initialValues={{
            prefix: "+91",
          }}
          scrollToFirstError
        >
          <Row justify="center">
            <img
              src={Logo}
              alt="React Logo"
              style={{ height: "50%", width: "50%" }}
            />
          </Row>
          <Row justify="center" style={{ marginTop: "3%" }}>
            <h1>Sign Up</h1>
          </Row>
          <Row className="hybrid">
            <Form.Item
              name="userType"
              rules={[
                {
                  required: true,
                  message: "Please select your user type",
                },
              ]}
            >
              <Radio.Group
                name="userType"
                value={regData?.userType}
                disabled={regData?.optVerified === true && true}
                onChange={(e) => handleOnChange(e)}
              >
                {/* <Radio value={1}>Buyer</Radio>
                <Radio value={2}>Seller</Radio> */}
                <Radio value="3">Hybrid (Buyer & Seller)</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>
          <p style={{ marginTop: 15 }}>Company Turnover *</p>
          <Form.Item
            name="turnOver"
            disabled={regData?.optVerified && true}
            // label="Company Turnover *"
            rules={[
              {
                required: true,
                message: "Please select your company turnover",
              },
            ]}
          >
            <Radio.Group
              disabled={regData?.optVerified && true}
              name="turnOver"
              onChange={(e) => handleOnChange(e)}
            >
              <Radio value="1">Less than 20 Lacs</Radio>
              <Radio value="2">More than 20 Lacs</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="name"
            onChange={(e) => handleOnChange(e)}
            disabled={regData?.optVerified && true}
            rules={[
              {
                required: true,
                message: "Please enter your name",
              },
            ]}
          >
            <Input
              disabled={regData?.optVerified && true}
              placeholder="Enter Your Name"
              className="signup-input-container"
              name="name"
            />
          </Form.Item>
          <Form.Item
            name="email"
            onChange={(e) => handleOnChange(e)}
            rules={[
              {
                type: "email",
                message: "Please enter valid E-mail",
              },
              {
                required: true,
                message: "Please enter your E-mail",
              },
            ]}
            disabled={regData?.optVerified && true}
          >
            <Input
              disabled={regData?.optVerified && true}
              name="email"
              placeholder="Enter Your E-Mail"
              className="signup-input-container"
            />
          </Form.Item>
          <Form.Item
            name="phone"
            onChange={(e) => handleOnChange(e)}
            rules={[
              {
                required: true,
                min: 10,
                message: "Please enter valid mobile number",
              },
            ]}
            disabled={regData?.optVerified && true}
          >
            <Input
              disabled={regData?.optVerified && true}
              name="phone"
              type="tel"
              maxLength={10}
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/(\..*)\./g, "$1");
              }}
              addonBefore={prefixSelector}
              placeholder="Enter Your Mobile Number"
              className="signup-input-container"
            />
          </Form.Item>
          <Form.Item
            name="password"
            onChange={(e) => handleOnChange(e)}
            rules={[
              { required: true, message: "Please enter your password" },
              { min: 8, message: "Password must have a minimum 8 letters." },
              {
                pattern: new RegExp(
                  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*_-])[A-Za-z\\d!@#$%^&*?&_-]{8,}$"
                ),
                message:
                  "Password must contain at least one lowercase letter, uppercase letter, number and special character.",
              },
            ]}
            hasFeedback
            disabled={regData?.optVerified === true && true}
          >
            <Input.Password
              disabled={regData?.optVerified && true}
              name="password"
              placeholder="Enter Password"
              className="signup-input-container"
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            disabled={regData?.optVerified && true}
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The two passwords that you entered does not match"
                  );
                },
              }),
            ]}
          >
            <Input.Password
              disabled={regData?.optVerified && true}
              placeholder="Confirm Password"
              className="signup-input-container"
            />
          </Form.Item>
          {regData?.optVerified === true && (
            <>
              {regData.turnOver === "1" ? (
                <Form.Item
                  hasFeedback
                  name="pan"
                  rules={[
                    {
                      required: true,
                      message: (
                        <p style={{ color: "red", marginTop: 2 }}>
                          Please enter your Pan Number
                        </p>
                      ),
                    },
                  ]}
                  validateStatus={
                    isVerify
                      ? "validating"
                      : !isVerify && isSuccessNo
                      ? "success"
                      : ""
                  }
                >
                  <Input
                    suffix={
                      isVerify
                        ? ""
                        : !isVerify && isSuccessNo
                        ? ""
                        : VerifyNumber
                    }
                    name="pan"
                    className="signup-input-container"
                    placeholder="Enter Your Pan Number"
                    onChange={(e) => handleGstOnChange(e)}
                    id="validating"
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  hasFeedback
                  name="gst"
                  rules={[
                    {
                      required: true,
                      message: (
                        <p style={{ color: "red", marginTop: 2 }}>
                          Please enter your GST Number
                        </p>
                      ),
                    },
                  ]}
                  validateStatus={
                    isVerify
                      ? "validating"
                      : !isVerify && isSuccessNo
                      ? "success"
                      : ""
                  }
                >
                  <Input
                    id="validating"
                    suffix={
                      isVerify
                        ? ""
                        : !isVerify && isSuccessNo
                        ? ""
                        : VerifyNumber
                    }
                    name="gst"
                    placeholder="Enter Your GST Number"
                    className="signup-input-container"
                    onChange={(e) => handleGstOnChange(e)}
                  />
                </Form.Item>
              )}

              <Form.Item name="companyName" value={companyName}>
                <input type="hidden" value={companyName} />
                <Input
                  value={companyName}
                  className="signup-input-container"
                  placeholder="Enter Your Company Name"
                  value={companyName}
                  name="companyName"
                  disabled={companyName !== "" && true}
                />
              </Form.Item>
              <input type="hidden" value={regData?.selectedIndustry} />
              <Form.Item
                name="industry"
                rules={[{ required: true, message: "Please Select Industry" }]}
              >
                <Select
                  name="industry"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  size="middle"
                  placeholder="Please select Industry"
                  className="signup-input-container"
                  onChange={(e) => handleIndustryChange(e)}
                  optionFilterProp
                  filterOption={false}
                  showSearch
                  showArrow={true}
                  onSearch={(e) =>
                    setIndustryFilter(
                      regData?.industry.length > 0 &&
                        regData?.industry.filter((i) =>
                          i.name.toLowerCase().includes(e.toLowerCase())
                        )
                    )
                  }
                >
                  {industryFilter.length > 0
                    ? industryFilter.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })
                    : regData?.industry.length > 0 &&
                      regData?.industry.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                </Select>
              </Form.Item>
              {regData?.selectedIndustry !== "" && (
                <>
                  {(regData?.userType === "1" || regData?.userType === "3") && (
                    <Form.Item
                      name="buyerChains"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Buyer Supply Chain",
                        },
                      ]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        mode="multiple"
                        size="middle"
                        placeholder="Please select buyer supply chain"
                        className="signup-input-container"
                        optionFilterProp
                        filterOption={false}
                        showSearch
                        showArrow={true}
                        onSearch={(e) =>
                          setBuyerFilter(
                            regData?.supply_chain.length > 0 &&
                              regData?.supply_chain.filter((i) =>
                                i.name.toLowerCase().includes(e.toLowerCase())
                              )
                          )
                        }
                        value={regData.buyer_supply_chain}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            buyer_supply_chain: e,
                          });
                          setBuyerFilter([]);
                        }}
                      >
                        {buyerFilter?.length > 0
                          ? buyerFilter.map((item) => (
                              <Option
                                value={item.id}
                                label={item.name}
                                key={item.id}
                              >
                                {item.name}
                              </Option>
                            ))
                          : regData?.supply_chain.length > 0 &&
                            regData?.supply_chain.map((item) => (
                              <Option
                                value={item.id}
                                label={item.name}
                                id={item.id}
                              >
                                {item.name}
                              </Option>
                            ))}
                      </Select>
                    </Form.Item>
                  )}
                  {(regData?.userType === "2" || regData?.userType === "3") && (
                    <Form.Item
                      name="sellerChains"
                      rules={[
                        {
                          required: true,
                          message: "Please select seller supply chain",
                        },
                      ]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        mode="multiple"
                        size="middle"
                        placeholder="Please Select Seller Supply Chain"
                        className="signup-input-container"
                        optionFilterProp
                        filterOption={false}
                        showSearch
                        showArrow={true}
                        onSearch={(e) =>
                          setSellerFilter(
                            regData?.supply_chain.length > 0 &&
                              regData?.supply_chain.filter((i) =>
                                i.name.toLowerCase().includes(e.toLowerCase())
                              )
                          )
                        }
                        value={regData.seller_supply_chain}
                        onChange={(e) => {
                          setRegData({
                            ...regData,
                            seller_supply_chain: e,
                          });
                          setSellerFilter([]);
                        }}
                      >
                        {sellerFilter.length > 0
                          ? sellerFilter.map((item) => (
                              <Option
                                value={item.id}
                                label={item.name}
                                key={item.id}
                              >
                                {item.name}
                              </Option>
                            ))
                          : regData?.supply_chain.length > 0 &&
                            regData?.supply_chain.map((item) => (
                              <Option
                                value={item.id}
                                label={item.name}
                                id={item.id}
                              >
                                {item.name}
                              </Option>
                            ))}
                      </Select>
                    </Form.Item>
                  )}
                </>
              )}
              {(regData?.buyer_supply_chain?.length !== 0 ||
                regData?.seller_supply_chain?.length !== 0) && (
                <Form.Item
                  name="category"
                  rules={[
                    { required: true, message: "Please select category" },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    mode="multiple"
                    size="middle"
                    name="category"
                    showArrow={true}
                    // optionLabelProp="label"
                    placeholder="Please select Category"
                    className="signup-input-container"
                    filterOption={false}
                    showSearch
                    onSearch={(e) =>
                      setCatFilter(
                        regData?.categories.length > 0 &&
                          regData?.categories.filter((i) =>
                            i.name.toLowerCase().includes(e.toLowerCase())
                          )
                      )
                    }
                    value={regData?.selectedCategories}
                    onChange={(e) => {
                      setRegData({
                        ...regData,
                        selectedCategories: e,
                      });
                      setCatFilter([]);
                    }}
                  >
                    {catFilter.length > 0
                      ? catFilter.map((item) => (
                          <Option
                            value={item.id}
                            label={item.name}
                            key={item.id}
                          >
                            {item.name}
                          </Option>
                        ))
                      : regData?.categories.length > 0 &&
                        regData?.categories.map((item) => (
                          <Option
                            value={item.id}
                            label={item.name}
                            id={item.id}
                          >
                            {item.name}
                          </Option>
                        ))}
                  </Select>
                </Form.Item>
              )}
            </>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%", borderRadius: 2 }}
            >
              {regData?.optVerified === false ? "Verify" : "Sign Up"}
            </Button>
          </Form.Item>
          <Row type="flex" justify="center" align="center">
            {" "}
            Already have an account?&nbsp;
            <Link to={ROUTES.LOGIN}> Log in</Link>
          </Row>
        </Form>
      </Row>

      <Modal
        closable={false}
        centered
        width={600}
        visible={regData.showModal}
        footer={false}
      >
        <Row justify="center" style={{ height: "100%" }}>
          <Col span={24}>
            <p
              className="otp-text"
              fontSize="1rem"
              fontFamily="GoogleSans-Medium"
              color="#000"
            >
              Step 1 - Verify Mobile Number
            </p>
          </Col>

          <Col span={22}>
            <OtpInput
              isInputNum={true}
              value={regData.mobOtp}
              onChange={(e) =>
                setRegData({
                  ...regData,
                  mobOtp: e,
                })
              }
              inputStyle={{ width: 80, height: 50, paddingLeft: 20 }}
              numInputs={6}
              shouldAutoFocus
              separator={<span> </span>} //
              inputStyle="otp-box"
              containerStyle="justify-evenly"
            />
          </Col>

          <Divider />

          <Col span={24}>
            <p
              className="otp-text"
              fontSize="1rem"
              fontFamily="GoogleSans-Medium"
              color="#000"
            >
              Step 2 - Verify Email
            </p>
          </Col>

          <Col span={22}>
            <OtpInput
              isInputNum={true}
              value={regData.emailOtp}
              onChange={(e) =>
                setRegData({
                  ...regData,
                  emailOtp: e,
                })
              }
              numInputs={6}
              separator={<span> </span>}
              inputStyle="otp-box"
              containerStyle="justify-evenly"
            />
          </Col>

          <Col
            span={24}
            style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}
          >
            <Button
              type="primary"
              style={{ width: "100%", height: 40 }}
              onClick={() => {
                _verifymobemail();
              }}
            >
              Verify With OTP
            </Button>
          </Col>
          <Col span={24} style={{ marginTop: 20, textAlign: "center" }}>
            <a
              onClick={() => {
                _resendOtp();
              }}
            >
              <u>Regenerate OTP</u>
            </a>
          </Col>
        </Row>
      </Modal>
    </Row>
  );
};

export default SignUp;
