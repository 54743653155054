import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ROUTES } from "./routes.constant";

import {
  Login,
  ResetPassword,
  ForgetPassword,
  VerifyOTP,
  SignUp,
  Auth,
  Backup2fa,
} from "../containers";
// import { ForgetPassword } from "../containers/ResetPassword";

const Routes = () => (
  <Switch>
    <Route restricted={false} exact path={ROUTES.LOGIN} component={Login} />
    <Route restricted={false} exact path={ROUTES.LOGINID} component={Login} />
    <Route restricted={false} exact path={ROUTES.SIGNUP} component={SignUp} />

    {/* FORGET PASSWORD */}
    <Route
      exact
      restricted={false}
      path={ROUTES.FORGOTPASSWORD}
      component={ForgetPassword}
    />
    <Route
      exact
      restricted={false}
      path={ROUTES.VERIFYOTPFORGOTPASSWORD}
      component={VerifyOTP}
    />
    <Route
      exact
      restricted={false}
      path={ROUTES.RESETPASSWORD}
      component={ResetPassword}
    />
    <Route
      exact
      restricted={false}
      path={ROUTES.RESETPASSWORD}
      component={ResetPassword}
    />
    <Route exact restricted={false} path={ROUTES.AUTH} component={Auth} />
    <Route
      exact
      restricted={false}
      path={ROUTES.BACKUP2FA}
      component={Backup2fa}
    />
    <Redirect from="*" to="/login" />
  </Switch>
);
export { Routes, ROUTES };
