import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { storeToken } from "../../utils";
import { auth2faSelector, auth2faStateAtom } from "../../recoil";
import { Logo } from "../../assets/icon";
import {
  Form,
  Row,
  Col,
  Button,
  Input,
  notification,
  Statistic,
  Spin,
} from "antd";
import { SERVER_URI } from "../../utils/server.constant";
import { ROUTES } from "../../route";
import OtpInput from "react-otp-input";
const { Countdown } = Statistic;
const Backup2fa = () => {
  const history = useHistory();
  const [smsAuth, setSmsAuth] = useState(false);
  const [backup, setBackup] = useState(false);
  const [success, setSuccess] = useState(false);
  const [resultToken, setResultToken] = useState("");
  const [second, setSecond] = useState(30);
  const [authentication, setAuthentication] = useRecoilState(auth2faSelector);
  const auth2fa = useRecoilValue(auth2faStateAtom);
  const openNotificationWithIcon = (type, title, message, test) => {
    var t = new Date();
    const deadline = t.setSeconds(t.getSeconds() + 5);
    const btn = <Countdown title="" value={deadline} format="ss" />;
    if (test) {
      notification[type]({
        message: title,
        description: message,
        btn,
        duration: 3,
      });
    } else {
      notification[type]({
        message: title,
        description: message,
      });
    }
  };
  const _handlelogInSuccessful = async (result) => {
    if (result?.flag) {
      await storeToken(result?.data?.token);
      await setSuccess(true);
      await setResultToken(result?.data?.token);
      await openNotificationWithIcon(
        "success",
        "2FA backup code verified",
        "Now, you are redairecting to ChaloMart Portal",
        "test"
      );
    } else {
      openNotificationWithIcon("error", "Error", result.info);
    }
  };
  const _handleVerify = async (values) => {
    let requestBody = {};
    if (backup) {
      requestBody = {
        identity: auth2fa?.identity,
        password: auth2fa?.password,
        prometheus: "true",
        backupcode: auth2fa?.backupcode,
      };
    } else {
      requestBody = {
        identity: auth2fa?.identity,
        password: auth2fa?.password,
        prometheus: "true",
        verify2faotp: values,
      };
    }
    await fetch(`${SERVER_URI}passport/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then(async (result) => {
        _handlelogInSuccessful(result);
      });
  };
  const _redairectToAdmin = async () => {
    if (process.env.NODE_ENV === "production") {
      window.location.assign(
        `${process.env.REACT_APP_PORTAL_URL}?jwt=${resultToken}`
      );
    } else {
      window.location.assign(`http://localhost:3006/?jwt=${resultToken}`);
    }
  };
  const _handlesmsAuthenticastion = async () => {
    await fetch(`${SERVER_URI}passport/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identity: auth2fa?.identity,
        password: auth2fa?.password,
        prometheus: "true",
        send2faotp: "true",
      }),
    })
      .then((response) => response.json())
      .then(async (result) => {
        if (result?.info) {
          setSmsAuth(true);
          setBackup(false);
          openNotificationWithIcon("success", "Success", result.info);
        } else {
          openNotificationWithIcon("error", "Error", result.info);
        }
      });
  };
  useEffect(() => {
    if (success === true && resultToken !== "") {
      setTimeout(() => {
        _redairectToAdmin();
      }, 3000);
    }
  }, [resultToken]);
  useEffect(() => {
    if (auth2fa?.identity === "") {
      history.push({
        pathname: ROUTES.LOGIN + "prometheus",
      });
    }
  }, []);
  const _setTime = async () => {
    if (smsAuth === true && backup !== true) {
      if (second <= 30 && second !== 0) {
        setTimeout(() => {
          setSecond(second - 1);
        }, 1000);
      } else if (second === 0) {
        setSmsAuth(false);
        setSecond(30);
        setSuccess(false);
        setResultToken("");
      }
    }
  };
  useEffect(() => {
    _setTime();
  });
  return (
    <Row
      className="login-flow-bg"
      style={{ width: "100%", height: "100%" }}
      justify="center"
      align="middle"
    >
      <Form
        className=" login-box"
        size="large"
        onFinish={_handleVerify}
        style={{
          width: success === true && resultToken !== "" ? "450px" : "400px",
        }}
      >
        <div className="logo text-center mb-20">
          <Logo className="chalomart-logo" />
        </div>

        <h1 className="text-center mb-10 f-30 bold">Two-Factor Backup</h1>
        {!smsAuth && !backup ? (
          <>
            <h5 className="text-center f-16">Choose backup Method</h5>
            <Row justify="space-between">
              <Col span={11}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  className="full-width mt-10 login-form-button"
                  onClick={() => {
                    setBackup(true);
                    setSmsAuth(false);
                  }}
                >
                  Backup Code
                </Button>
              </Col>
              <Col span={11}>
                <Button
                  type="primary"
                  size="middle"
                  className="full-width mt-10 login-form-button"
                  onClick={() => {
                    _handlesmsAuthenticastion();
                  }}
                >
                  SMS Authentication
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <>
            {!backup && smsAuth ? (
              <>
                <h5 className="text-center f-16">
                  An SMS with your verification code was sent to your phone.
                  Request new code in{" "}
                  {second < 10 ? `0${second}s` : `${second}s`}
                </h5>
                <Row style={{ width: "100%" }} className="mt-20">
                  <Col span={success === true && resultToken !== "" ? 22 : 24}>
                    <Form.Item
                      name="otp"
                      rules={[
                        {
                          required: true,
                          message: "Please input your OTP from SMS",
                        },
                      ]}
                    >
                      <OtpInput
                        inputStyle={{ width: 50, height: 50 }}
                        numInputs={6}
                        shouldAutoFocus
                        isInputNum={true}
                        separator={<span> </span>}
                        inputStyle="otp-box1"
                        containerStyle="justify-evenly"
                        onChange={(e) => {
                          if (e.length === 6) {
                            setAuthentication({
                              ...authentication,
                              verify2faotp: e,
                            });
                            _handleVerify(e);
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {success === true && resultToken !== "" ? (
                    <Col span={2}>
                      <Spin size="small" className="mt-10" />
                    </Col>
                  ) : null}
                </Row>
              </>
            ) : (
              <>
                <h5 className="text-center f-16">Enter your Backup code.</h5>
                <Row style={{ width: "100%" }} className="mt-20">
                  <Col span={24}>
                    <Form.Item
                      name="otp"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Backup code ",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter your Backup code  "
                        onChange={(e) => {
                          setAuthentication({
                            ...authentication,
                            backupcode: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    className="full-width mt-10 login-form-button"
                  >
                    Verify
                  </Button>
                </Row>
              </>
            )}
          </>
        )}

        <Row type="flex" justify="center" align="center" className="mt-20">
          Backup Method didnt't work? &nbsp;
          <Col>
            <Link to={ROUTES.AUTH}> Go back to app authenticator </Link>
            or
            <Link to={`${ROUTES.LOGIN}/prometheus`}> Logout</Link>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};
export default Backup2fa;
