import { atom } from "recoil";

const auth2faStateAtom = atom({
  key: "auth2faStateAtom",
  default: {
    identity: "",
    password: "",
    prometheus: "",
    code2fa: "",
    backupcode: "",
    verify2faotp: "",
  },
});
export { auth2faStateAtom };
