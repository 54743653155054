import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";

import { Routes } from "./route";
import * as serviceWorker from "./serviceWorker";

import "./globalstyle.less";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <React.Suspense
        fallback={
          <div className="center-alignment">
            <p>Loading</p>
          </div>
        }
      >
        <RecoilRoot>
          <Routes />
        </RecoilRoot>
      </React.Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
