import React, { useEffect } from "react";
import { Row, Form, Input, Col, Button, notification } from "antd";
import { Logo } from "../../assets/icon";
import { useRecoilValue } from "recoil";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";

import { SERVER_URI } from "../../utils";
import { ROUTES } from "../../route";
import { resetStateAtom } from "../../recoil";

const ResetPassword = (props) => {
  const [form] = Form.useForm();
  const enterdData = useRecoilValue(resetStateAtom);

  useEffect(() => {
    if (!enterdData.radioValue) {
      props.history.push({
        pathname: ROUTES.FORGOTPASSWORD,
      });
    }
  }, []);

  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };
  const onFinish = async (values) => {
    let reqBody = {};
    if (enterdData.radioValue === "1") {
      reqBody = {
        init: "reset",
        identity: enterdData.email,
        password: values.password,
      };
    } else {
      reqBody = {
        init: "reset",
        identity: enterdData.mobile,
        password: values.password,
      };
    }
    console.log("reqBody", reqBody);
    await fetch(`${SERVER_URI}passport/reset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.flag) {
          props.history.push({
            pathname: ROUTES.LOGIN,
          });
          openNotificationWithIcon("success", "Success", result.info);
        } else {
          openNotificationWithIcon("error", "Error", result.info);
        }
      });
  };
  return (
    <Row
      className="login-flow-bg"
      style={{ width: "100%", height: "100%" }}
      justify="center"
      align="middle"
    >
      <Form
        className=" login-box"
        size="large"
        form={form}
        name="register"
        style={{
          backgroundColor: "white",
          overflow: "scroll",
          width: 480,
          padding: 20,
        }}
        onFinish={onFinish}
        initialValues={{}}
        scrollToFirstError
      >
        <div className="logo text-center mb-20">
          <Logo className="chalomart-logo" />
        </div>

        <h1 className="text-center mb-10 f-24">Reset Password</h1>
        <Row style={{ width: "100%" }}>
          <Col span={24}>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please enter your password" },
                {
                  min: 8,
                  message: "Password must have a minimum 8 latters.",
                },
                {
                  pattern: new RegExp(
                    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*_-])[A-Za-z\\d!@#$%^&*?&_-]{8,}$"
                  ),
                  message:
                    "Password must contain at least one lowercase letter, uppercase letter, number, and special character.",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                name="password"
                placeholder="Enter Password"
                iconRender={(visible) =>
                  visible ? (
                    <EyeTwoTone twoToneColor="#F77415" />
                  ) : (
                    <EyeInvisibleOutlined twoToneColor="#F77415" />
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="confirm"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two passwords that you entered does not match"
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Confirm Password"
                iconRender={(visible) =>
                  visible ? (
                    <EyeTwoTone twoToneColor="#F77415" />
                  ) : (
                    <EyeInvisibleOutlined twoToneColor="#F77415" />
                  )
                }
                // className="signup-input-container"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="full-width mt-10 login-form-button"
          >
            Submit
          </Button>
        </Form.Item>
        <Row gutter={(2, 1)}></Row>
      </Form>
    </Row>
  );
};
export { ResetPassword };
