import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import OtpInput from "react-otp-input";
import { storeToken } from "../../utils";
import { auth2faSelector, auth2faStateAtom } from "../../recoil";
import { Logo } from "../../assets/icon";
import { Form, Row, Col, notification, Statistic, Spin } from "antd";
import { SERVER_URI } from "../../utils/server.constant";
import { ROUTES } from "../../route";

const Authentication2Fa = () => {
  const { Countdown } = Statistic;
  const history = useHistory();
  const [authentication, setAuthentication] = useRecoilState(auth2faSelector);
  const auth2fa = useRecoilValue(auth2faStateAtom);
  const [success, setSuccess] = useState(false);
  const [resultToken, setResultToken] = useState("");
  const openNotificationWithIcon = (type, title, message, test) => {
    var t = new Date();
    const deadline = t.setSeconds(t.getSeconds() + 5);
    const btn = <Countdown title="" value={deadline} format="ss" />;
    if (test) {
      notification[type]({
        message: title,
        description: message,
        duration: 3,
        btn,
      });
    } else {
      notification[type]({
        message: title,
        description: message,
      });
    }
  };
  const _handlelogInSuccessful = async (result) => {
    if (result?.flag) {
      await storeToken(result?.data?.token);
      await setSuccess(true);
      await setResultToken(result?.data?.token);
      await openNotificationWithIcon(
        "success",
        "2FA code verified",
        "Now, you are redairecting to ChaloMart Portal",
        "test"
      );
    } else {
      openNotificationWithIcon("error", "Error", result.info);
    }
  };
  const _handleVerify = async (values) => {
    await fetch(`${SERVER_URI}passport/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identity: auth2fa?.identity,
        password: auth2fa?.password,
        prometheus: "true",
        code2fa: values,
      }),
    })
      .then((response) => response.json())
      .then(async (result) => {
        _handlelogInSuccessful(result);
      });
  };
  const _redairectToAdmin = async () => {
    if (process.env.NODE_ENV === "production") {
      window.location.assign(
        `${process.env.REACT_APP_PORTAL_URL}?jwt=${resultToken}`
      );
    } else {
      window.location.assign(`http://localhost:3006/?jwt=${resultToken}`);
    }
  };
  useEffect(() => {
    if (success === true && resultToken !== "") {
      setTimeout(() => {
        _redairectToAdmin();
      }, 3000);
    }
  }, [resultToken]);

  useEffect(() => {
    if (auth2fa?.identity === "") {
      history.push({
        pathname: ROUTES.LOGIN + "prometheus",
      });
    }
  }, []);
  return (
    <Row
      className="login-flow-bg"
      style={{ width: "100%", height: "100%" }}
      justify="center"
      align="middle"
    >
      <Form
        className=" login-box"
        size="large"
        style={{
          width: success === true && resultToken !== "" ? "450px" : "400px",
        }}
      >
        <div className="logo text-center mb-20">
          <Logo className="chalomart-logo" />
        </div>

        <h1 className="text-center mb-10 f-30 bold">
          Two-Factor Authentication
        </h1>
        <h5 className="text-center f-16">
          Open your authentication app and enter the code for ChaloMart.
        </h5>
        <Row
          style={{ width: "100%" }}
          className="mt-20"
          justify="space-between"
        >
          <Col span={success === true && resultToken !== "" ? 22 : 24}>
            <Form.Item
              name="otp"
              rules={[
                {
                  required: true,
                  message: "Please input your One time 2FA password",
                },
              ]}
            >
              <OtpInput
                inputStyle={{ width: 50, height: 50 }}
                numInputs={6}
                shouldAutoFocus
                isInputNum={true}
                separator={<span> </span>}
                inputStyle="otp-box1"
                containerStyle="justify-evenly"
                onChange={(e) => {
                  if (e.length === 6) {
                    setAuthentication({
                      ...authentication,
                      code2fa: e,
                    });
                    _handleVerify(e);
                  }
                }}
              />
            </Form.Item>
          </Col>
          {success === true && resultToken !== "" ? (
            <Col span={2}>
              <Spin size="small" className="mt-10" />
            </Col>
          ) : null}
        </Row>

        <Row type="flex" justify="center" align="center">
          Having Problem accessing authenticator app? &nbsp;
          <Col>
            <Link to={ROUTES.BACKUP2FA}>Use Backup Method </Link>
            or
            <Link to={`${ROUTES.LOGIN}prometheus`}> Logout</Link>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};
export default Authentication2Fa;
