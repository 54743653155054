import React, { useEffect } from "react";
import { Form, Row, Button, Col, notification } from "antd";
import { Logo } from "../../assets/icon";
import OtpInput from "react-otp-input";
import { useRecoilValue } from "recoil";
import { resetStateAtom } from "../../recoil";
import { SERVER_URI } from "../../utils";
import { ROUTES } from "../../route";

const VerifyOTP = (props) => {
  useEffect(() => {
    if (!enterdData.radioValue) {
      props.history.push({
        pathname: ROUTES.FORGOTPASSWORD,
      });
    }
  }, []);
  const [form] = Form.useForm();
  const enterdData = useRecoilValue(resetStateAtom);

  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  const onFinish = async (values) => {
    let reqBody = {};
    if (enterdData.radioValue === "1") {
      reqBody = {
        init: "reset",
        identity: enterdData.email,
        otp: values.otp,
      };
    } else {
      reqBody = {
        init: "reset",
        identity: enterdData.mobile,
        otp: values.otp,
      };
    }
    console.log("reqBody", reqBody);
    await fetch(`${SERVER_URI}passport/verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.flag) {
          props.history.push({
            pathname: ROUTES.RESETPASSWORD,
          });
          openNotificationWithIcon("success", "Success", result.info);
        } else {
          openNotificationWithIcon("error", "Error", result.info);
        }
      });
  };
  const _resendOtp = async () => {
    await fetch(`${SERVER_URI}passport/request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identity:
          enterdData.radioValue === "1" ? enterdData.email : enterdData.mobile,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.flag) {
          // props.history.push({
          //   pathname: ROUTES.VERIFYOTPFORGOTPASSWORD,
          // });
          openNotificationWithIcon("success", "Success", result.info);
        } else {
          openNotificationWithIcon("error", "Error", result.info);
        }
      });
  };
  return (
    <Row
      className="login-flow-bg"
      style={{ width: "100%", height: "100%" }}
      justify="center"
      align="middle"
    >
      <Form
        className=" login-box"
        size="large"
        form={form}
        name="register"
        style={{ width: "30%" }}
        onFinish={onFinish}
        scrollToFirstError
      >
        <div className="logo text-center mb-20">
          <Logo className="chalomart-logo" />
        </div>

        <h1 className="text-center mb-10 f-24">Verify OTP</h1>
        {/* <h5 className="text-center f-16">
          Please enter your registered email address or mobile number.
        </h5>
        <h4 className="text-center mb-30 f-16">
          OTP to reset the password will be forwarded.
        </h4> */}
        <Form.Item
          name="otp"
          rules={[
            {
              required: true,
              message: "Please enter  OTP",
            },
          ]}
        >
          <OtpInput
            inputStyle={{ width: 80, height: 50, paddingLeft: 20 }}
            numInputs={6}
            shouldAutoFocus
            isInputNum={true}
            separator={<span> </span>}
            inputStyle="otp-box"
            containerStyle="justify-evenly"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="full-width mt-10 login-form-button"
          >
            Verify
          </Button>
        </Form.Item>
        <Col span={24} style={{ marginTop: 20, textAlign: "center" }}>
          <a
            onClick={() => {
              _resendOtp();
            }}
          >
            <u>Regenerate OTP</u>
          </a>
        </Col>
      </Form>
    </Row>
  );
};
export { VerifyOTP };
