export const ROUTES = {
  // auth

  LOGIN: "/login/",
  LOGINID: "/login/:id",
  SIGNUP: "/signup",
  REGISTEROTPVERIFY: "/registrationverify",
  FORGOTPASSWORD: "/forgotpassword",
  VERIFYOTPFORGOTPASSWORD: "/forgetpasswordverify",
  RESETPASSWORD: "/setpassword",

  //2FA
  AUTH: "/auth/2fa",
  BACKUP2FA: "/auth/2fa/backup",
};
