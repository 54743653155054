import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../route";
import { useHistory } from "react-router-dom";
import { storeToken, getToken, clearToken } from "../utils";
import { Logo } from "../assets/icon";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Checkbox,
  notification,
  Tabs,
  Tag,
  message,
} from "antd";
import {
  MobileTwoTone,
  MailTwoTone,
  LockTwoTone,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { SERVER_URI } from "../utils/server.constant";
import { auth2faSelector } from "../recoil";
const queryString = require("query-string");

const { TabPane } = Tabs;
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

const Login = ({ props }) => {
  const [auth2fa, setAuth2fa] = useRecoilState(auth2faSelector);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isVendor, setIsVendor] = useState(false);
  const [isNBFC, setIsNBFC] = useState(false);
  const [phone, setPhone] = useState();
  const [otpVerified, setOtpVerified] = useState(false);
  const [activeTab, setActivetab] = useState("Email");
  const [disable, setDisable] = useState(true);
  const [second, setSecond] = useState(30);
  const history = useHistory();
  var link = document.location.href.split("/")[4];

  //get redirection link  after login
  const parsed = queryString.parse(document.location.search);
  let redirect = parsed.redirect;
  console.log(redirect);

  const _verifyIsAdmin = async () => {
    if (link === "prometheus") {
      await setIsAdmin(true);
    }
  };
  const _verifyIsVendor = async () => {
    if (link === "vendor") {
      await setIsVendor(true);
    }
  };
  const _verifyIsNBFC = async () => {
    if (link === "nbfc") {
      await setIsNBFC(true);
    }
  };
  const _checkTokenActive = async () => {
    const token = await getToken();
    if (await token) {
      await fetch(`${SERVER_URI}passport/login`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then(async (result) => {
          if (result.flag) {
            if (redirect && link !== "prometheus") {
              //modify redirection url with JWT token
              let query = redirect.split("?");
              if (query[1]) {
                console.log("not redirect link");
                //if there is already any param in url
                redirect = `${redirect}&&jwt=${token}`;
              } else {
                redirect = `${redirect}?jwt=${token}`;
              }
              window.location.assign(`${redirect}`);
            } else {
              if (
                document.referrer &&
                window.location.href !== document.referrer
              ) {
                window.location.assign(`${document.referrer}?jwt=${token}`);
              } else {
                window.location.assign(
                  `${process.env.REACT_APP_PORTAL_URL}?jwt=${token}`
                );
              }
            }
          } else {
            await clearToken(token);
          }
        });
    }
  };
  useEffect(() => {
    _verifyIsAdmin();
    _verifyIsVendor();
    _verifyIsNBFC();
    _checkTokenActive();
  }, []);
  const _setTime = async () => {
    if (otpVerified === true) {
      if (second <= 30 && second !== 0) {
        setTimeout(() => {
          setSecond(second - 1);
        }, 1000);
      } else if (second === 0) {
        setDisable(false);
      }
    }
  };
  useEffect(() => {
    _setTime();
  });
  const _handlelogInSuccessful = async (result) => {
    if (result.flag) {
      await storeToken(result.data.token);
      if (link === "prometheus") {
        if (process.env.NODE_ENV === "production") {
          window.location.assign(
            `${process.env.REACT_APP_PORTAL_URL}?jwt=${result.data.token}`
          );
        } else {
          window.location.assign(
            `http://localhost:3006/?jwt=${result.data.token}`
          );
        }
      } else if (link === "vendor") {
        if (process.env.NODE_ENV === "production") {
          window.location.assign(
            `${process.env.REACT_APP_PORTAL_URL}?jwt=${result.data.token}`
          );
        } else {
          window.location.assign(
            `http://localhost:3006/?jwt=${result.data.token}`
          );
        }
      } else if (link === "nbfc") {
        if (process.env.NODE_ENV === "production") {
          window.location.assign(
            `${process.env.REACT_APP_PORTAL_URL}?jwt=${result.data.token}`
          );
        } else {
          window.location.assign(
            `http://localhost:3006/?jwt=${result.data.token}`
          );
        }
      } else if (
        redirect &&
        link !== "prometheus" &&
        link !== "vendor" &&
        link !== "nbfc"
      ) {
        //modify redirection url with JWT token

        let query = redirect.split("?");
        if (query[1]) {
          //if there is already any param in url
          redirect = `${redirect}&&jwt=${result.data.token}`;
        } else {
          redirect = `${redirect}?jwt=${result.data.token}`;
        }
        window.location.assign(`${redirect}`);
      } else {
        if (document.referrer && window.location.href !== document.referrer) {
          window.location.assign(
            `${document.referrer}?jwt=${result.data.token}`
          );
        } else {
          window.location.assign(
            `${process.env.REACT_APP_PORTAL_URL}?jwt=${result.data.token}`
          );
        }
      }
    } else {
      openNotificationWithIcon("error", "Error", result.info);
    }
  };
  const onFinish = async (values) => {
    if (activeTab === "OTP") {
      await fetch(`${SERVER_URI}passport/sivotp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile: "+91" + values.phone,
          otp: values.otp,
        }),
      })
        .then((response) => response.json())
        .then(async (result) => {
          _handlelogInSuccessful(result);
        });
    } else if (activeTab === "Email") {
      setAuth2fa({
        ...auth2fa,
        identity: values.email,
        password: values.password,
        prometheus: isAdmin && "true",
        vendor: isVendor && "true",
        nbfc: isNBFC && "true",
      });

      try {
        const adminApiBody = {
          identity: values.email,
          password: values.password,
          prometheus: "true",
        };
        const vendorApiBody = {
          identity: values.email,
          password: values.password,
          vendor: "true",
        };
        const nbfcApiBody = {
          identity: values.email,
          password: values.password,
          nbfc: "true",
        };
        const apiBody = {
          identity: values.email,
          password: values.password,
        };
        await fetch(`${SERVER_URI}passport/signin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: isAdmin
            ? JSON.stringify(adminApiBody)
            : isVendor
            ? JSON.stringify(vendorApiBody)
            : isNBFC
            ? JSON.stringify(nbfcApiBody)
            : JSON.stringify(apiBody),
        })
          .then((response) => response.json())
          .then(async (result) => {
            if (result?.data?.route) {
              history.push({
                pathname: ROUTES.AUTH,
              });
            } else {
              _handlelogInSuccessful(result);
            }
          });
      } catch (err) {
        openNotificationWithIcon("error", "Error", "Login failed");
      }
    }
  };
  const _handlegetOtp = async () => {
    if (phone) {
      await fetch(`${SERVER_URI}passport/sirotp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile: "+91" + phone,
        }),
      })
        .then((response) => response.json())
        .then(async (result) => {
          if (result.flag === true) {
            setOtpVerified(true);

            openNotificationWithIcon("success", "Success", result.info);
          } else {
            openNotificationWithIcon("error", "Error", result.info);
          }
        });
    } else {
      message.error("Please Enter Mobile Number");
    }
  };
  return (
    <Row
      className="login-flow-bg"
      style={{ width: "100%", height: "100%" }}
      justify="center"
      align="middle"
    >
      <Form
        size="large"
        name="normal_login"
        className="login-form login-box"
        initialValues={{
          remember: true,
        }}
        onFinish={
          activeTab === "OTP" && otpVerified === false
            ? _handlegetOtp
            : onFinish
        }
      >
        <div className="logo text-center mb-20">
          <Logo className="chalomart-logo" />
        </div>
        <h4 className="text-center mb-10 f-24">Welcome Back, User !</h4>

        <Tabs
          defaultActiveKey="Email"
          onChange={(e) => setActivetab(e)}
          className="mb-5"
        >
          <TabPane tab="Login with Email" key="Email">
            <h4 className=" mb-10 f-12">
              Please login to continue with your registered email
            </h4>
            <Col span={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email" || "number",
                    required: activeTab === "Email" ? true : false,
                    message: "Please enter your E-mail",
                  },
                ]}
              >
                <Input
                  prefix={
                    <MailTwoTone
                      className="site-form-item-icon"
                      twoToneColor="#F77415"
                    />
                  }
                  placeholder="Enter your registered email"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: activeTab === "Email" ? true : false,
                    message: "Please enter your password",
                  },
                ]}
              >
                <Input.Password
                  prefix={
                    <LockTwoTone
                      className="site-form-item-icon"
                      twoToneColor="#F77415"
                    />
                  }
                  type="password"
                  placeholder="Password"
                  iconRender={(visible) =>
                    visible ? (
                      <EyeTwoTone twoToneColor="#F77415" />
                    ) : (
                      <EyeInvisibleOutlined twoToneColor="#F77415" />
                    )
                  }
                />
              </Form.Item>
            </Col>
          </TabPane>
          {!isAdmin && !isVendor && !isNBFC && (
            <TabPane tab="Login with Mobile Number" key="OTP">
              <h4 className=" mb-10 f-12">
                Please login to continue with your registered mobile number{" "}
              </h4>
              <Col span={24}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: activeTab === "OTP" ? true : false,
                      message: "Please enter valid mobile number",
                    },
                  ]}
                >
                  <Input
                    value={phone}
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                    type="tel"
                    maxlength={10}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*)\./g, "$1");
                    }}
                    prefix={
                      <MobileTwoTone
                        className="site-form-item-icon"
                        twoToneColor="#F77415"
                      />
                    }
                    placeholder="Enter your registered mobile number"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="otp"
                  rules={[
                    {
                      required:
                        activeTab === "OTP"
                          ? otpVerified
                            ? true
                            : false
                          : false,
                      message: "Please enter your OTP",
                    },
                  ]}
                >
                  <Input
                    disabled={!otpVerified}
                    prefix={
                      <LockTwoTone
                        className="site-form-item-icon"
                        twoToneColor="#F77415"
                      />
                    }
                    type="number"
                    placeholder="Enter OTP"
                  />
                </Form.Item>
              </Col>
            </TabPane>
          )}
        </Tabs>

        <Row gutter={(2, 1)}>
          <Col span={16}>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Link to={ROUTES.FORGOTPASSWORD}>
              <p className="login-form-forgot">Forgot Password?</p>
            </Link>
          </Col>

          <Col span={24}>
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="full-width mt-30 login-form-button "
              >
                {activeTab === "OTP"
                  ? otpVerified
                    ? "Submit OTP"
                    : "Get OTP"
                  : " Log in"}
              </Button>
              {activeTab === "OTP" && otpVerified && (
                <text
                  onClick={() =>
                    disable === false &&
                    (_handlegetOtp(), setSecond(30), setDisable(true))
                  }
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    color: disable ? "#000" : "#F77415",
                    alignSelf: "center",
                    textAlign: "center",
                    cursor: disable ? "none" : "pointer",
                  }}
                  disabled={disable}
                >
                  Resend OTP {second !== 0 ? `in ${second}s` : null}
                </text>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row type="flex" justify="center" align="center">
          Don't have an account yet?&nbsp;
          <Link to={ROUTES.SIGNUP}> Sign Up here</Link>
        </Row>
      </Form>
    </Row>
  );
};

export default Login;
