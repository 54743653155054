import React, { useState } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  notification,
  Button,
  Radio,
} from "antd";
import { useRecoilState } from "recoil";
import { ROUTES } from "../../route";
import { Logo } from "../../assets/icon";
import { SERVER_URI } from "../../utils";
import { resetStateSelector } from "../../recoil";

const ForgetPassword = (props) => {
  const [form] = Form.useForm();
  const [radioValue, setRadioValue] = useState("1");
  const [drafVal, setDrafVal] = useRecoilState(resetStateSelector);
  const { Option } = Select;
  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };
  const onFinish = async (values) => {
    setDrafVal({
      ...drafVal,
      radioValue: values.identity,
      email: values.email,
      mobile: values.prefix + values.phone,
    });
    await fetch(`${SERVER_URI}passport/request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identity:
          values?.identity == "1" ? values.email : values.prefix + values.phone,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.flag) {
          props.history.push({
            pathname: ROUTES.VERIFYOTPFORGOTPASSWORD,
          });
          openNotificationWithIcon("success", "Success", result.info);
        } else {
          openNotificationWithIcon("error", "Error", result.info);
        }
      });
  };
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 90 }} name="prefix">
        <Option value="+91">+91</Option>
      </Select>
    </Form.Item>
  );
  return (
    <Row
      className="login-flow-bg"
      style={{ width: "100%", height: "100%" }}
      justify="center"
      align="middle"
    >
      <Form
        className=" login-box"
        size="large"
        form={form}
        name="register"
        style={{ width: "30%" }}
        onFinish={onFinish}
        initialValues={{
          prefix: "+91",
          identity: "1",
        }}
        scrollToFirstError
      >
        <div className="logo text-center mb-20">
          <Logo className="chalomart-logo" />
        </div>

        <h1 className="text-center mb-10 f-24">Forget Password?</h1>
        <h5 className="text-center f-16">
          Please enter your registered email address or mobile number.
        </h5>
        <h4 className="text-center mb-30 f-16">
          OTP to reset the password will be forwarded.
        </h4>
        <Row>
          <Col span={24}>
            <Form.Item name="identity" className="mb-10">
              <Radio.Group
                onChange={(e) => setRadioValue(e.target.value)}
                value={radioValue}
                defaultValue={radioValue}
              >
                <Radio value="1">Email</Radio>
                <Radio value="2">Mobile</Radio>
              </Radio.Group>
            </Form.Item>
            {radioValue === "1" ? (
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Please enter valid E-mail",
                  },
                  {
                    required: true,
                    message: "Please enter your E-mail!",
                  },
                ]}
              >
                <Input
                  name="email"
                  placeholder="Enter Your E-Mail"
                  className="signup-input-container"
                />
              </Form.Item>
            ) : (
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number",
                  },
                ]}
              >
                <Input
                  name="phone"
                  type="tel"
                  maxLength={10}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*)\./g, "$1");
                  }}
                  addonBefore={prefixSelector}
                  placeholder="Enter Your Mobile No."
                  className="signup-input-container"
                />
              </Form.Item>
            )}
            <Col span={24}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="full-width mt-10 login-form-button"
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};
export { ForgetPassword };
