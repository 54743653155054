const LABEL = {
  TOKEN: "token",
  NAME: "NAME",
};

//Token
const storeToken = async (token) =>
  await localStorage.setItem(LABEL.TOKEN, token);
const getToken = async () => await localStorage.getItem(LABEL.TOKEN);
const clearToken = async () => await localStorage.removeItem(LABEL.TOKEN);

//Name
const storeName = async (name) => await localStorage.setItem(LABEL.NAME, name);
const GetName = async () => await localStorage.getItem(LABEL.NAME);

//clear storage
const clearLocalstorage = async () => await localStorage.clear();
export {
  storeToken,
  getToken,
  clearToken,
  clearLocalstorage,
  storeName,
  GetName,
};
