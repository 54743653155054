import { atom } from "recoil";

const registerStateAtom = atom({
  key: "registerStateAtom",
  default: {
    mobOtp: "",
    emailOtp: "",
    userType: "",
    categories: [],
    companyName: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    gstNo: "",
    pan: "",
    turnOver: "",
    prefix: "+91",
    selectedIndustry: "",
    selectedCategories: "",
    showModal: false,
    industryArr: [],
    supply_chain: [],
    selectedSuppyChain: [],
    buyer_supply_chain: [],
    seller_supply_chain: [],
    industry: [],
    optVerified: false,
    tradeName: "",
    premisePlot: "",
    premiseBuilding: "",
    premiseStreet: "",
    premisePin: "",
  },
});
export { registerStateAtom };
// gstNo: '24DYQPS5104C1ZG',
